import React, { useState } from "react";
import { Navbar } from "../../components/Nav";
import "./grant.style.css";
import { Helmet } from "react-helmet";

export const Grant = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => setShowForm(!showForm);

  return (
    <>
      <Helmet>
        <title>Student Grant - Elizabeth Grace Foundation</title>
        <meta
          name="description"
          content="In Elizabeth Grace Foundation, we give grsnt to eligible students in the tertiary institution. he candidate, in addition, must be on a First Class (4.50 and above on a 5 point scale or equivalent scale) in a University or a Distinction in a Polytechnic or College of Education."
        />
      </Helmet>

      <header className="abt-header">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-lg-5 py-4 text-center">
            <h3 className="egf-header-title mb-4">Check Your Eligibility </h3>
            <p className="egf-pg mb-0 mx-lg-5 mx-4">
              Before beginning with the application process, double check that
              you are eligible for consideration. Ideal candidates selected for
              this award should currently be a student of a tertiary institution
              in Nigeria. The candidate, in addition, must be on a{" "}
              <strong className="fw-600">
                {" "}
                First Class (4.50 and above on a 5 point scale or equivalent
                scale) in a University or a Distinction in a Polytechnic or
                College of Education.{" "}
              </strong>
              If this looks like you, kindly proceed to make an application.
            </p>
            {!showForm && (
              <button
                className="btn egf-btn-primary mt-4"
                onClick={handleShowForm}
              >
                Proceed
              </button>
            )}
          </div>
        </div>
      </header>

      {showForm && (
        <section className="container">
          <div className="form-container">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSegBRh6e6GUHNt3E3qGsAyQedzslcZhVhJUy62clHHsSRuaJg/viewform?embedded=true"
              width="750"
              height="500"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </div>
        </section>
      )}
    </>
  );
};
