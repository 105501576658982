import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navbar } from "../components/Nav";
import { Footer } from "../components/Footer";
import { Home } from "../pages/Home";
import { About } from "../pages/About";
import { WhatWeDo } from "../pages/What-We-Do";
import { Event } from "../pages/Event";
import { Contact } from "../pages/Contact";
import { Donate } from "../pages/Donate";
import { SingleEvent } from "../pages/View-Event";
import { Grant } from "../pages/Grant";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { Terms } from "../pages/Terms";
import ScrollToTop from "../utilites";

export const MainRoutes = () => {
  return (
    <Router>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/events" element={<Event />} />
          <Route path="/events/:id" element={<SingleEvent />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/grant" element={<Grant />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ScrollToTop>

      <Footer />
    </Router>
  );
};
