import React from "react";
import "./donateSection.style.css";
import { Link } from "react-router-dom";

export const DonateSection = () => {
  return (
    <section className="donate-section">
      <div className="container">
        <div className="text-ssection">
          <h3 className="egf-title">Join Us In Impacting Lives</h3>
          <p className="egf-pg mt-4 mb-5 mx-lg-5">
            We value diversity and are committed to building an inclusive
            community of support. Do you support curing eye diseases and giving
            people hope for a better life? Would you love to make that impact
            from anywhere in the world?
          </p>

          <Link to="/donate" className="btn egf-btn-secondary">
            Donate
          </Link>
        </div>
      </div>
    </section>
  );
};
