import React from "react";
import "./event.style.css";
import { Navbar } from "../../components/Nav";
import EventImag from "../../assets/images/bg/event-img.png";
import { Bounce, Fade, Zoom } from "react-reveal";

export const Event = () => {
  return (
    <>
      <header className="wwd-header egf-header-bg">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-4">
            <h3 className="egf-title mb-4">
              <Bounce>Upcoming Events</Bounce>
            </h3>
            <p className="">
              <Fade>
                Discover interesting events of the day and add them to your
                schedule. Find out what is happening in your local area or
                around the world and stay current. See forthcoming programmes
                below.
              </Fade>
            </p>
          </div>
        </div>
      </header>

      <section className="container py-5 my-lg-5">
        <div className="row">
          {/* Coming soon */}
          <div className="col-12 mb-5">
            <h3 className="egf-title text-center coming-soon-text">
              Coming Soon
            </h3>
          </div>

          {/* <div className="col-12 mb-5 event-card">
            <Zoom>
              <img src={EventImag} className="img-fluid mb-5" alt="event" />
            </Zoom>
            <h3 className="egf-title egf-title-b-border">
              Official Launch of Foundation
            </h3>
            <p className="mb-5 egf-pg">
              <Fade>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur.
              </Fade>
            </p>

            <button className="btn egf-btn-primary mb-lg-5">Know More</button>
          </div> */}
        </div>
      </section>
    </>
  );
};
