import React, { useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo/EGF-Logo-1.svg";
import "./nav.styles.css";
// import bootstrap from 'bootstrap';

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  const collapseNav = () => {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  };

  const listenForScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    console.log(scrolled);
  };

  window.addEventListener("scroll", listenForScroll);

  const confirmActiveLink = (currentLink) => {
    if (location.pathname === currentLink) return "active";
  };

  const navMenu = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: " What We Do",
      link: "/what-we-do",
    },
    {
      name: "Events",
      link: "/events",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  return (
    // <div className="fxied-top">
    <nav className="navbar navbar-expand-lg sticky-top">
      <div className="container">
        <Link to="/" onClick={collapseNav}>
          <img src={Logo} className="navbar-brand" alt="EGF-logo" />
        </Link>

        <button
          ref={navButton}
          className="navbar-toggler "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          ref={linksContainerRef}
        >
          {/* <div className="d-flex align-items-center w-100"> */}
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            {navMenu.map((navItem, index) => {
              return (
                <li className="nav-item" key={`${index}-${navItem.link}`}>
                  <Link
                    onClick={collapseNav}
                    to={navItem.link}
                    className={`nav-link ${confirmActiveLink(navItem.link)}`}
                  >
                    {navItem.name}
                  </Link>
                </li>
              );
            })}
          </ul>

          <button
            onClick={() => {
              navigate("/donate");
              collapseNav();
            }}
            className="btn egf-btn-primary"
          >
            Donate
          </button>
          {/* </div> */}
        </div>
      </div>
    </nav>
    // </div>
  );
};
