import React from "react";
import { Navbar } from "../../components/Nav";

export const SingleEvent = () => {
  return (
    <>
      <header>
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-5 text-center">
            <h3 className="egf-title mb-4">Single Event </h3>
          </div>
        </div>
      </header>
    </>
  );
};
