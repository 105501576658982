import React, { useState } from "react";
import { Navbar } from "../../components/Nav";
import "./about.style.css";
import OurVision from "../../assets/images/bg/about-vision-bg.png";
import { TrusteeCard } from "../../components/TrusteeCard";
import { teamLists } from "../../mock-data";
import { Modal, NavItem } from "react-bootstrap";
import CloseModal from "../../assets/images/icon/close-modal-icon.svg";
import { Fade, Bounce, Zoom } from "react-reveal";
import { Helmet } from "react-helmet";

export const About = () => {
  const [show, setShow] = useState(false);
  const [teamDetails, setTeamDetails] = useState("");

  const openReadMore = (team_detail) => {
    setShow(true);
    setTeamDetails(team_detail);
  };

  const handleClose = () => {
    setShow(false);
    setTeamDetails("");
  };

  return (
    <>
      <Helmet>
        <title>
          About Elizabeth Grace Foundation - We Invest In Transforming Lives.
        </title>
        <meta
          name="description"
          content="Our vision is To build an inclusive community of support for the beneficiary of individuals in the communities, which will foster growth in the education, self empowerment and social development of the nation. "
        />
      </Helmet>
      <header className="abt-header egf-header-bg">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-lg-5 py-4">
            <h3 className="egf-header-title mb-4">
              <Bounce> We Invest In Transforming Lives</Bounce>
            </h3>
            <p className="egf-pg">
              <Fade>
                Imagine a world where every child has access to safe water, food
                and shelter, where every individual has the opportunity to
                receive an education and where every community can thrive. We
                have seen firsthand how a little bit of help can go a long way
                in making someone's life better. That's why we invest in
                organisations and initiatives that provide access to education,
                employment, skill acquisition and other opportunities that can
                help people get back on their feet and improve their lives. With
                our team of experts who are passionate about helping people
                reach their goals, we are proud to be able to provide necessary
                assistance and make a difference.
              </Fade>
            </p>
          </div>
        </div>
      </header>

      <section className="abt-vision-section py-4">
        <div className="container py-5">
          <div className="row mx-0">
            <div className="col-xl-5 col-lg-6">
              <Zoom>
                <img src={OurVision} alt="Our-vision-img" />
              </Zoom>
            </div>

            <div className="col-xl-7 col-lg-6 d-flex align-items-center pe-lg-5 mt-4 mt-lg-0">
              <div>
                <h3 className="egf-title egf-title-b-border">Our Vision</h3>
                <p className="me-lg-5 egf-pg">
                  <Fade>
                    Our vision is To build an inclusive community of support for
                    the beneficiary of individuals in the communities, which
                    will foster growth in the education, self empowerment and
                    social development of the nation. We are giving 100% to
                    achieve this and we are delighted to be moving at an
                    encouraging pace, with full support from members of the
                    public and our very own team.
                  </Fade>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5 py-5">
        <h3 className="egf-title mb-5 text-center">Meet Our Trustees</h3>

        <div className="row mx-0">
          {teamLists.map((team) => {
            return (
              <div className="col-12 mb-lg-5 mb-4">
                <TrusteeCard
                  photo={team.photo}
                  photo_alt={NavItem.photo_alt}
                  name={team.name}
                  title={team.title}
                  bio_summary={team.bio_summary}
                  bio_detail={team.bio_detail}
                  onOpenModal={() => openReadMore(team)}
                />
              </div>
            );
          })}
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="pt-5 team-modal"
        centered
      >
        <div
          className="d-flex justify-content-end close-container"
          onClick={handleClose}
        >
          <img src={CloseModal} alt="close" />
        </div>
        <Modal.Body>
          <div className="row modal-card">
            <div className="col-lg-4 col-md-3">
              <div className="photo">
                <img src={teamDetails.photo} alt={teamDetails.photo_alt} />
                <div className="img-overlay"></div>
              </div>
            </div>
            <div className="col-lg-8 col-md-9">
              <div className="text-section ms-lg-3">
                <h4 className="title">{teamDetails.name}</h4>
                <p className="sub-title">{teamDetails.title} </p>
                <div
                  className="bio"
                  dangerouslySetInnerHTML={{ __html: teamDetails.bio_detail }}
                ></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
