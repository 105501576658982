import React from "react";
import { DonateSection } from "../../components/Donate-Section";
import "./home.style.css";
import StudentGrant from "../../assets/images/icon/student-grant.svg";
import YouthEmpowerment from "../../assets/images/icon/youth-empowerment.svg";
import MedicalSupport from "../../assets/images/icon/medical-support.svg";
import CommunityProject from "../../assets/images/icon/community-project.svg";
import BgStudentGrant from "../../assets/images/bg/stu-grant-bg.png";
// import UnicefIcon from "../../assets/images/logo/unicef.svg";
import GabAndNuellaConceptLogo from "../../assets/images/logo/Gab-&-Nuella-concept.svg";
import GabAndNuellaPetroleumLogo from "../../assets/images/logo/Gab-&-Nuella-petroleum-logo.svg";
import GabAndNuellaEnergyLogo from "../../assets/images/logo/Gab-&-Nuella-Energy-logo.svg";
import MbraizLogo from "../../assets/images/logo/MBRAIZ-LOGO.svg";
// import GBGHLogo from "../../assets/images/logo/GBGH_logo.svg";
// import WHOLogo from "../../assets/images/logo/World_Health_Organization_logo.svg";
import AboutImg1 from "../../assets/images/bg/about-img-1.png";
import AboutImg2 from "../../assets/images/bg/about-img-2.png";
import AboutImg3 from "../../assets/images/bg/about-img-3.png";
import AboutImg4 from "../../assets/images/bg/about-img-4.png";
import { Navbar } from "../../components/Nav";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/navigation";
import HeroImg1 from "../../assets/images/bg/home-hero-img-1.png";
import HeroImg2 from "../../assets/images/bg/home-hero-img-2.png";
import HeroImg3 from "../../assets/images/bg/home-hero-img-3.png";
import GivingIcon from "../../assets/images/icon/giving-icon.svg";
import { Link } from "react-router-dom";
import { Zoom, Bounce, Fade } from "react-reveal";
import { testimonies } from "../../mock-data/testimonies";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Elizabeth Grace Foundation - Giving Value To Lives...</title>
        <meta
          name="description"
          content="Elizabeth Grace Foundation is a nonprofit organisation that aims to uplift the communities by engaging them in projects, programs and giving the needed support towards Youth Empowerment, Healthcare, Research and Development."
        />
      </Helmet>
      <header className="h-header egf-header-bg">
        <div className="container">
          {/* <Navbar /> */}

          <div className="row py-3 py-lg-0 mx-0">
            <div className="col-lg-7 d-flex align-items-center hero-text-row">
              <div className="hero-text-section">
                <h3 className="egf-header-title mb-0">
                  <Bounce>Giving Value To Lives... </Bounce>
                </h3>
                <p className="egf-pg mt-4 mb-4 mb-lg-5">
                  <Fade>
                    Elizabeth Grace Foundation is a nonprofit organisation that
                    aims to uplift the communities by engaging them in projects,
                    programs and giving the needed support towards Youth
                    Empowerment, Healthcare, Research and Development.
                    {/* We are
                    dedicated to helping humanity and giving value to lives with
                    keen interest in Education, Youth empowerment, Healthcare
                    and overall Community Development. You're invited to be a
                    part of this noble cause and make a difference today. */}
                  </Fade>
                </p>

                <Link to="/donate" className="btn egf-btn-primary">
                  Donate
                </Link>
              </div>

              <div className="giving-container">
                <div className="giving-icon-holder">
                  <img src={GivingIcon} alt="giving sign" />
                </div>
              </div>
            </div>

            <div className="col-lg-5 mt-5 mt-lg-0 pe-lg-0">
              <div className="ms-auto hero-swiper-img-section">
                <Swiper
                  className="mySwiper"
                  loop={true}
                  effect={"cube"}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                  }}
                  modules={[Autoplay, EffectCube]}
                >
                  <SwiperSlide>
                    <img
                      src={HeroImg1}
                      className="img-fluid"
                      alt="Hero section"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={HeroImg2}
                      className="img-fluid"
                      alt="Hero section"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={HeroImg3}
                      className="img-fluid"
                      alt="Hero section"
                    />
                  </SwiperSlide>
                </Swiper>

                <div className="giving-container giving-container-mobile">
                  <div className="giving-icon-holder">
                    <img src={GivingIcon} alt="giving sign" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="h-about-section py-5">
        <div className="container my-5">
          <div className="row mx-0">
            <div className="col-12">
              <div className="row help-count p-4 mx-0 shadow-lg">
                <div className="col-md-3 col-6 d-flex justify-content-md-center">
                  <div className="count-details">
                    <h3 className="">200+</h3>
                    <p className="mb-1 mb-lg-3">Grants to students</p>
                  </div>
                </div>

                <div className="col-md-3 col-6 d-flex justify-content-md-center">
                  <div className="count-details">
                    <h3 className="">100+</h3>
                    <p className="mb-1 mb-lg-3">Empowered Youths</p>
                  </div>
                </div>

                <div className="col-md-3 col-6 d-flex justify-content-md-center">
                  <div className="count-details mb-0">
                    <h3 className="">50+</h3>
                    <p className="mb-1 mb-lg-3">Medical Support</p>
                  </div>
                </div>

                <div className="col-md-3 col-6 d-flex justify-content-md-center">
                  <div className="count-details mb-0">
                    <h3 className="">10+</h3>
                    <p className="mb-1 mb-lg-3">Community Projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row my-5 mx-0">
            <div className="col-lg-6">
              <div className="abt-grid-container me-lg-5">
                <div className="grid-box">
                  <Zoom>
                    <img
                      src={AboutImg1}
                      className="img-fluid"
                      alt="about-img"
                    />
                  </Zoom>
                </div>
                <div className="grid-box">
                  <Zoom>
                    <img
                      src={AboutImg2}
                      className="img-fluid"
                      alt="about-img"
                    />
                  </Zoom>
                </div>
                <div className="grid-box">
                  <Zoom>
                    <img
                      src={AboutImg3}
                      className="img-fluid"
                      alt="about-img"
                    />
                  </Zoom>
                </div>
                <div className="grid-box last-box">
                  <Zoom>
                    <img
                      src={AboutImg4}
                      className="img-fluid"
                      alt="about-img"
                    />
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0 text-start">
              <p className="egf-sub-title mb-3">ABOUT US</p>
              <h3 className="egf-title mb-0 px-1 px-lg-0">
                Committed To Impacting Lives
              </h3>
              <p className="egf-pg mt-4 mb-4 mb-lg-5 me-lg-4">
                <Fade>
                  The Elizabeth Grace Foundation (EGF) was founded in [2021] to
                  tackle the issues of Youth Empowerment, Education, Healthcare
                  and Community Development. By extension, the foundation was
                  established to cater to the challenges associated with
                  poverty, and inequality among different communities. <br />
                  Born out of the need to bridge the gap between developed and
                  underdeveloped areas, we are giving Student Grants, activating
                  Youth Empowerment and Skill Acquisition Programmes, providing
                  Medical Support for the indigent and aged and embarking on a
                  number of community projects. Including provision of portable
                  drinking water, leveraging Renewable Energy source for Power
                  Supply, amongst others.
                </Fade>
              </p>

              <Link to="/about" className="btn egf-btn-primary mt-2">
                Know More
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="h-our-scheme-section py-5 mb-3">
        <div className="container">
          <p className="mb-2 egf-sub-title text-center">OUR SCHEME</p>
          <h3 className="mb-5 egf-title text-center px-1 px-lg-0">
            What We Are Doing To Help Humanity
          </h3>

          <div className="row my-5 mx-0">
            <div className="col-lg-3 col-md-6 mb-4">
              <Zoom>
                <div className="card scheme-card">
                  <div className="image-holder">
                    <img src={StudentGrant} alt="student_grant" />
                  </div>

                  <h5 className="my-3 mx-xl-4 mx-lg-2">Grants to Students</h5>
                  <p className="mb-0 mx-xl-4 mx-lg-2">
                    Find scholarships and grants by exploring our database of
                    funding opportunities
                  </p>
                </div>
              </Zoom>
            </div>

            <div className="col-lg-3 col-md-6 mb-4">
              <Zoom>
                <div className="card scheme-card">
                  <div className="image-holder">
                    <img src={YouthEmpowerment} alt="student_grant" />
                  </div>

                  <h5 className="my-3 mx-xl-4 mx-lg-2">Youth Empowerment</h5>
                  <p className="mb-0 mx-xl-4 mx-lg-2">
                    Take charge of your life with our help
                  </p>
                </div>
              </Zoom>
            </div>

            <div className="col-lg-3 col-md-6 mb-4">
              <Zoom>
                <div className="card scheme-card">
                  <div className="image-holder">
                    <img src={MedicalSupport} alt="student_grant" />
                  </div>

                  <h5 className="my-3 mx-xl-4 mx-lg-2">Medical Support</h5>
                  <p className="mb-0 mx-xl-4 mx-lg-2">
                    Access quality healthcare and medical services
                  </p>
                </div>
              </Zoom>
            </div>

            <div className="col-lg-3 col-md-6">
              <Zoom>
                <div className="card scheme-card">
                  <div className="image-holder">
                    <img src={CommunityProject} alt="student_grant" />
                  </div>

                  <h5 className="my-3 mx-xl-4 mx-lg-2">Community Projects</h5>
                  <p className="mb-0 mx-xl-4 mx-lg-2">
                    Building infrastructure for growth and development of
                    communities
                  </p>
                </div>
              </Zoom>
            </div>
          </div>

          <Link to="/what-we-do">
            <button className="btn egf-btn-primary d-flex mx-auto">
              Know More
            </button>
          </Link>
        </div>
      </section>

      <section className="h-student-grant-section py-5">
        <div className="container">
          <div className="row mx-0 d-flex">
            <div className="col-lg-6 d-flex align-items-center mt-5 mt-lg-0 order-2 order-lg-1">
              <div className="text-start">
                <h3 className="egf-title me-lg-5 px-lg-0">
                  We Are Giving Grants to Support Students
                </h3>
                <p className="egf-pg my-4 me-lg-5">
                  <Fade>
                    We are responding to the high cost of Secondary and Tertiary
                    education by providing grants to students for their study
                    requirements. Elizabeth Grace Foundation believes that no
                    student should be denied their chance at furthering their
                    education because of financial difficulty. That is why we
                    are responding with grants that will provide the money you
                    need to reach your educational goals.
                  </Fade>
                </p>

                <Link to="/grant" className="btn egf-btn-primary">
                  Apply
                </Link>
              </div>
            </div>

            <div className="col-lg-6 d-flex justify-content-lg-end order-1">
              <img
                src={BgStudentGrant}
                alt="student-grant-bg"
                className="bg-img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5">
        <h3 className="egf-title mb-xl-5 mb-lg-4 mb-4 text-center testimonies-header">
          Here’s What Our Beneficiaries Say About Us
        </h3>

        <div className="row mx-0">
          <div className="col-12">
            <div className="h-testimony-section">
              <div className="row ">
                <div className="col-lg-12 d-flex align-items-cemter">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    centeredSlides={false}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                      },
                      991: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1200: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                  >
                    {testimonies
                      .sort((a, b) => a.order - b.order)
                      .map(({ name, info, description }, id) => {
                        return (
                          <SwiperSlide key={`${id}-${name}`}>
                            <div className="testimony-card">
                              <p className="mb-4 desc">{description}</p>

                              <div className="about-user-container">
                                <h5 className="mb-1">{name}</h5>
                                <p className="mb-0">{info}</p>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container py-5 h-partner-section">
        <h5 className="egf-sub-title mb-5 text-center">OUR PARTNERS</h5>

        <div className="row my-lg-5 mt-3 mb-0 mx-0 d-flex justify-content-center">
          <Zoom>
            <div className="col-lg-3 col-md-4 col-4 d-flex justify-content-center mb-5">
              <img
                src={GabAndNuellaConceptLogo}
                alt="partner"
                className="partner-logo"
              />
            </div>
          </Zoom>
          <Zoom>
            <div className="col-lg-3 col-md-4 col-4 d-flex justify-content-center mb-5">
              <img src={MbraizLogo} alt="partner" className="partner-logo" />
            </div>
          </Zoom>
          <Zoom>
            <div className="col-lg-3 col-md-4 col-4 d-flex justify-content-center mb-5">
              <img
                src={GabAndNuellaEnergyLogo}
                alt="partner"
                className="partner-logo"
              />
            </div>
          </Zoom>
          <Zoom>
            <div className="col-lg-3 col-md-4 col-4 d-flex justify-content-center mb-5">
              <img
                src={GabAndNuellaPetroleumLogo}
                alt="partner"
                className="partner-logo"
              />
            </div>
          </Zoom>
        </div>
      </section>

      <DonateSection />
    </>
  );
};
