export const testimonies = [
  {
    order: 5,
    name: "Mr Pogoson Benjamin",
    info: "",
    description:
      " On behalf of Peace Pogoson and the entire family of Mr & Mrs Benjamin Pogoson we want to thank Elizabeth Grace Foundation for their financial educational aid to our daughter. This singular act is divine, timely and well appreciated. We therefore pray for the foundation that blessings you never expected, God Almighty will make you possess it. May God enlarge and make more rooms for the foundation. May God connect you to destiny helpers that will lift the foundation to greater heights. May Gods protection be on the entire staff.",
  },

  {
    order: 3,
    name: "Mr & Mrs Ogunbode-Paul",
    info: "",
    description:
      "I want to use this medium to appreciate the foundation for their immense support concerning our daughter's (Marvellous Ogunbode) school fees. I never expect it in a time like this at all but l am shock and highly elated when l received the alert yesterday. I pray that God will continue to bless and enlarge the foundation beyond measure in Jesus name. The God given vision and mission of the foundation will not dwindled in Jesus name. On behalf of myself and my wonderful husband,we say Thank you so much.",
  },

  {
    order: 6,
    name: "Oluwadarasimi Ajayi",
    info: "",
    description:
      "Your continuos generousity is seemingly endless. Thank you for this amazing generosity towards our family. This gesture really mean so much to us. Many thanks for all you do. The Almighty God will continue to bless you. He will always make a way for you in the wilderness and water in the desert. The foundation will grow from strength to strength.",
  },

  {
        order: 4,
    name: "Mr Olaleye Joshua Oladipo",
    info: "",
    description:
      "We hereby use this medium to humbly convey our hearty and sincere appreciation of the financial support given to our daughter; Emmanuella Semilore Oladipo ,as regards her admission into JSS 1. We are very grateful. We pray that the Almighty God will bless,enlarge,favour and lift up this worthwhile organization to the apex of global preference,greater accomplishment and success. Thank you gigantically sir.",
  },

  {
    order: 1,
    name: "Joseph Odubona",
    info: "400 level Medical Student, College of Medicine, University of Lagos.",
    description:
      "On the 31st August, 2023, I received a credit alert of One hundred and ninety thousand naira(#190,000) from the Elizabeth Grace Foundation to pay for my next level tuition fee and I wish to express my heartfelt and profound gratitude to the foundation for this wonderful initiative. May God bless the Chairman and every trustee member of the scholarship board abundantly. Thank you once again sir/ma.",
  },

  {
    order: 2,
    name: "Obilana family",
    info: "",
    description:
      "This is to register my profound appreciation and heartfelt gratitude for the scholarship  payment of N259, 000 for our beloved daughter Jomiloju Esther Obilana. The Lord will continue to prosper the foundation in Jesus Name. I remain grateful. For and on behalf of Obilana family.",
  },

  {
    order: 7,
    name: "Akingbohungbe Oluwaseun",
    info: "",
    description:
      "I, Akingbohungbe Oluwaseun and my son (Akingbohungbe Daniel)  gladly use this medium to express my profound gratitude to the chairman, executives and every member of Elizabeth Grace Foundation for the award of academic scholarship. I am exceedingly grateful. May God continue to bless you the more and take you and the foundation to global relevance (Amen). Thanks. Yours Faithfully,",
  },

  {
    order: 8,
    name: "Akinrotimi O.S.",
    info: "Mass Communication Department, LASPOTECH ",
    description:
      "Thank you to Ellizabeth Grace Foundation for this opportunity. It is very rare to see platforms like this which motivate undergraduates with distinctions in their field of study. I also assure the foundation that this grant received will be put to good use. ",
  },
  {
    order: 9,
    name: "Jacob A.",
    info: "",
    description:
      "I sincerely appreciate the financial support given for the payment of my daughter’s tuition fees. I am short of words to describe my appreciation to the Chairman and members of the Board of Trustees. It is my prayer that the Almighty  God continue to bless this foundation",
  },
  {
    order: 10,
    name: "Aderoju O.M.",
    info: "Insurance Department, LASPOTECH ",
    description:
      "I appreciate Elizabeth Grace Foundation for this great opportunity given to students which I happen to also be a beneficiary. It is my prayer that the foundation grow more than where it is niw.",
  },
  {
    order: 11,
    name: "Olayinka O.",
    info: "",
    description:
      "I appreciate the financial support to my Child's Education. The support came at the right time when we were burdened financially. Hence, it provided a succor in such a time of need. I pray that the Foundation to continue to wax great.",
  },
  {
    order: 12,
    name: "Akingbohungbe O.",
    info: "",
    description:
      "I gladly express my profund gratitude to the chairman, executive and every member of Elizabeth Grace Foundation for the award of academic scholarship given to my son. I am exceedingly grateful. May God continue to bless you the more and take the foundation to a global relevance.",
  },
  {
    order: 13,
    name: "Sofowora S.P.",
    infor: "",
    description:
      "I express my heartfelt gratitude for your help in financing my son’s education. This assistance for me is a warm and bright ray of light representing good and caring people who are attentive to the plight of others. Being that my needs are many with the current peculiar situation, this aid helps me greatly.",
  },
  {
    order: 14,
    name: "Mr & Mrs Ogunbode",
    info: "",
    description:
      "We sincerely appreciate the kind gesture received from the foundation. The surprise package is highly appreciated. I pray Abraham's blessings shall be the portion of the foundation. The foundation will grow from strength to strength in Jesus name.",
  },
  {
    order: 15,
    name: "Omotola Y.",
    infor: "",
    description:
      "I was diagnosed of a heart related disease and the foundation paid my admission fee and also for my medications which gave me access to further treatment. I sincerely appreciate the foudation’s support",
  },
  {
    order: 16,
    name: "Bakare I.",
    info: "",
    description:
      "Elizabeth Grace Foundation helped out when I was down with kidney issues. This came as an emergency for me but the foundation came to my rescue. They paid my admission fee and also got me medications which enabled further treatment. I really appreciate the support given to me.",
  },
];
