import React from "react";
import "./what-we-do.style.css";
import { Navbar } from "../../components/Nav";
import StudentGrant from "../../assets/images/bg/scheme-student-grant.png";
import MedicalSupport from "../../assets/images/bg/scheme-medical-support.png";
import YouthEmpowerment from "../../assets/images/bg/scheme-youth-empowerment.png";
import CommunityProject from "../../assets/images/bg/scheme-community-project.png";
import { Bounce, Fade, Zoom } from "react-reveal";
import { Helmet } from "react-helmet";

export const WhatWeDo = () => {
  return (
    <>
      <Helmet>
        <title>
          What We do In Elizabeth Grace Foundation - We Invest In Transforming
          Lives.
        </title>
        <meta
          name="description"
          content="We give Grant to Students, Empower thee Youth, Provide Medical Support, and Build Community Projeects"
        />
      </Helmet>

      <header className="wwd-header egf-header-bg">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-lg-5 py-4">
            <h3 className="egf-title mb-4">
              <Bounce>Our Scheme</Bounce>
            </h3>
            <p className="mx-lg-5">
              <Fade>
                There are many organisations out there, working hard to help
                people in need. We have a variety of social initiatives that
                help children, women, the poor and the elderly. Read more about
                the most important ones here.
              </Fade>
            </p>
          </div>
        </div>
      </header>

      <section className="container my-5 my-lg-5 py-lg-5">
        <div className="row scheme-row-item mx-xl-0 mx-0 mb-5 pb-lg-5 pb-4">
          <div className="col-xl-5 col-lg-6">
            <Zoom>
              <img
                src={StudentGrant}
                className="img-fluid"
                alt="student grant scheme"
              />
            </Zoom>
          </div>
          <div className="col-xl-7 col-lg-6 mt-4 mt-lg-0">
            <h3 className="egf-title egf-title-b-border">Grants To Students</h3>
            <p className="mb-0 me-xl-5 egf-pg">
              <Fade>
                The cost of studying can be high, especially for those that come
                from a low-income household. There are various student grants
                available to help you get the education you want and deserve.
                You can find information about available grants and other
                opportunities to reduce the cost of your education without
                having to take on any more debt or make any further sacrifice
                than you’re willing to make. If you’re currently enrolled in
                high-school, or if you’re a parent who is looking to complete
                your degree so you can be better equipped to support your
                family, this might be the perfect opportunity for you!
              </Fade>
            </p>
          </div>
        </div>

        <div className="row scheme-row-item mx-xl-0 mx-0 mb-5 pb-lg-5 pb-4">
          <div className="col-xl-5 col-lg-6">
            <Zoom>
              <img
                src={YouthEmpowerment}
                className="img-fluid"
                alt="student grant scheme"
              />
            </Zoom>
          </div>
          <div className="col-xl-7 col-lg-6 mt-4 mt-lg-0">
            <h3 className="egf-title egf-title-b-border">Youth Empowerment</h3>
            <p className="mb-0 me-xl-5 egf-pg">
              <Fade>
                The most meaningful work opportunities are available in tech and
                startups, which remain the most desirable industries among 18-35
                year olds. Technology is the driving force behind Youth
                Empowerment. We actively commit to empowering the next
                generation of global citizen champions and we have trained over
                100 people worldwide, who are actively shaping the world around
                them with their innovative and creative solutions. We provide
                access to the best peer-to-peer and in-person training so youths
                can become resilient, take action towards their dreams, and live
                a life they love. Choose from the list of skills including
                Catering, Electrical installations, Plumbing, Fashion design and
                many more. You can learn a skill and build your own startup. Do
                you already have a skill and more support? Do reach out to us
                via our contact media, we are happy to help.
              </Fade>
            </p>
          </div>
        </div>

        <div className="row scheme-row-item mx-xl-0 mx-0 mb-5 pb-lg-5 pb-4">
          <div className="col-xl-5 col-lg-6">
            <Zoom>
              <img
                src={MedicalSupport}
                className="img-fluid"
                alt="student grant scheme"
              />
            </Zoom>
          </div>
          <div className="col-xl-7 col-lg-6 mt-4 mt-lg-0">
            <h3 className="egf-title egf-title-b-border">Medical Support</h3>
            <p className="mb-0 me-xl-5 egf-pg">
              <Fade>
                A healthy future for your family is within reach with our
                services. We believe that everyone deserves to have access to
                affordable healthcare. Our job is simply to help you understand
                your options and provide necessary medical support. With our
                active medical team, patients have benefited from free tests,
                prescriptions and discounted surgeries. We are also open to
                provide support during emergencies.
              </Fade>
            </p>
          </div>
        </div>

        <div className="row scheme-row-item mx-xl-0 mx-0 mb-5 pb-lg-5 pb-4">
          <div className="col-xl-5 col-lg-6">
            <Zoom>
              <img
                src={CommunityProject}
                className="img-fluid"
                alt="student grant scheme"
              />
            </Zoom>
          </div>
          <div className="col-xl-7 col-lg-6 mt-4 mt-lg-0">
            <h3 className="egf-title egf-title-b-border">Community Projects</h3>
            <p className="mb-0 me-xl-5 egf-pg">
              <Fade>
                Attending to the needs of the community is of great impact to
                everyone in the community. Some basic needs should be accessible
                to all and sundry. We make this happen by embarking on projects
                in developing communities across the globe. We have embarked on
                a couple of homelessness prevention projects which entailed
                provision of shelter for the less privileged and we are working
                on the next set of annual projects across neighbouring
                communities.
              </Fade>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
