import React from "react";
import { Navbar } from "../../components/Nav";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Our Privacy Policy - Elizabeth Grace Foundation</title>
      </Helmet>

      <header className="abt-header egf-header-bg">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-lg-5 py-4">
            <h3 className="egf-header-title mb-4">Our Privacy Policy</h3>
            <p className="egf-pg">Document amended September 20, 2022</p>
          </div>
        </div>
      </header>

      <section className="container py-5">
        <h5 className="egf-title-2 mb-4">Introduction</h5>
        <p className="mb-5 egf-pg">
          Elizabeth Grace Foundation treats your privacy with the highest
          importance. Our Data Protection Privacy Policies detail the measures
          we take to preserving and safely guarding your privacy when you visit
          our website or generally communicate with our personnel. These Data
          Protection Privacy Policies have been approved and provided by our
          legal advisors.
        </p>

        <h5 className="egf-title-2 mb-4">Data Collection</h5>
        <p className="egf-pg mb-5">
          At any time there’s a need to, we may collect, use, store and transfer
          different kinds of Personal Data about you which we have grouped
          together as follows:
          <br /> <br />
          <ol type="1">
            <li>
              {" "}
              Identity Data which includes first name, last name, username or
              similar identifier, title, date of birth and gender.
            </li>
            <li>
              Contact Data which includes residential address, email address and
              telephone numbers.
            </li>
            <li>
              Human Resource Data which includes information on your employment
              history, professional and educational information submitted upon
              applying for employment with us.
            </li>
            <li>
              Technical Data which includes internet prototype="1"col (IP)
              address, domain name, your login data, browser type and version,
              time zone setting and location, browser plug-in types and
              versions, operating system and platform, and other technology on
              the devices you use to access this website.
            </li>
            <li>
              Profile Data which includes your username and password, purchases
              or orders made by you, your interests, preferences, feedback and
              survey responses.
            </li>
            <li>
              Usage Data which includes information about how you use our
              website and services.
            </li>
            <li>
              Marketing and Communications Data which includes your preferences
              in receiving marketing communications from us and our Affiliated
              Third Parties and your communication preferences.
            </li>
          </ol>
          <br /> <br />
          You provide this information through direct interaction when you visit
          our website, sign up to our newsletters or publications, request
          marketing materials to be sent to you, respond to surveys, complete
          our feedback or comment form, provide your business card to any of our
          staff, sign our visitor management form, complete other forms, apply
          for employment through our careers page, or contact us to request for
          any information or other correspondence by post, email, our website or
          otherwise. As you interact with our website, we will automatically
          collect technical data about your equipment, browsing actions and
          patterns. We collect this data by using cookie, and other similar
          technologies. Please see our cookie policy for further details. We do
          not intentionally or knowingly collect any Sensitive Personal Data. We
          ask that you do not send to us nor disclose such Sensitive Personal
          Data save where required for a specific purpose.
        </p>

        <h5 className="egf-title-2 mb-4">Use Of Personal Data</h5>
        <p className="egf-pg mb-5">
          We collect, process and store your Personal Data to help us better
          connect with you. The following are the purposes for which we collect
          your Personal Data:
          <br /> <br />
          <ol type="1">
            <li>
              To monitor, review, evaluate and improve your experience when you
              visit our website
            </li>
            <li>
              To analyse the traffic on our website, including determining the
              number of visitors to the website and analyse how they navigate
              the website.
            </li>
            <li>
              To invite you to complete a survey or provide feedback to us on
              specific matters.
            </li>
            <li>
              At any time, you request information from us via a form or other
              electronic transmission we may use your Personal Data to fulfil
              that request and keep a record of such request and how it was
              handled, for quality assurance and service improvement purposes.
            </li>
            <li>
              To keep you updated on our activities, programmes and events where
              your explicit consent has been given.
            </li>
            <li>
              To notify you of changes to our websites or relevant processes.
            </li>
            <li>
              We may also use your information or allow Affiliated Third Parties
              such as our affiliate companies or partners use of this Personal
              Data, to offer you information about unrelated products or
              services you may be interested in. We or such Affiliated Third
              Parties can only communicate with you if you have expressly
              consented to such communication and data use.
            </li>
            <li>
              We may share your personal data with Affiliated Third Parties such
              as service providers who we have engaged to assist with providing
              certain services on our behalf, for which they require your
              personal data.
            </li>
            <li>
              Where we have any contracts with you which create a commitment, we
              may require contact or use of your information to perform the
              contract.
            </li>
            <li>
              To process or manage your appointments with any of our staff.
            </li>
            <li>
              To fulfil legal/ regulatory obligations or to report any criminal
              or unethical activity.
            </li>
            <li>
              To store either on our central computer system or a third-party
              Computer’s central computer system for archiving and back up
              purposes.
            </li>
          </ol>
          <br />
          Be aware that we do not reveal identifiable information about you to
          our advertisers, though we may at times share statistical visitor
          information with our advertisers.
        </p>

        <h5 className="egf-title-2 mb-4">Change Of Purpose</h5>
        <p className="egf-pg mb-5">
          We will only use your Personal Data for the aforementioned purposes,
          unless we reasonably consider that we need to use it for another
          reason and that reason is compatible with the original purpose. If you
          wish to get an explanation as to how the processing for the new
          purpose is compatible with the original purpose, please contact us.
          <br />
          <br />
          If we need to use your Personal Data for an unrelated purpose, we will
          notify you and request for your express consent.
        </p>

        <h5 className="egf-title-2 mb-4">Personal Data Rights</h5>
        <p className="egf-pg mb-5">
          Data Protection Laws provides you with certain rights in relation to
          the information that we collect about you.
          <br /> <br />
          <ol type="1">
            <li>
              The right to withdraw consent previously given to us or our
              Affiliated Third Parties. In order to make use of your personal
              data, we would have obtained your consent. For consent to be
              valid, it must be given voluntarily. In line with regulatory
              requirements, consent cannot be implied, and we ensure that you
              have the opportunity to read our data protection privacy policy
              before you provide your consent. Consent in respect of Sensitive
              Personal Data must be explicit and will be given by you in writing
              to us. The consent of minors (under the age of 18) will always be
              protected and obtained from the minor’s representatives in
              accordance with applicable regulatory. You can ask us or
              Affiliated Third Parties to stop sending you marketing messages at
              any time by logging onto the website and checking or unchecking
              relevant boxes to adjust your marketing preferences or by
              following the opt-out links on any marketing message sent to you.
            </li>
            <li>
              The right to request that we delete your Personal Data that is in
              our possession, subject however to retention required for legal
              purposes and the time required technically to delete such
              information.
            </li>
            <li>
              The right to request for access to your Personal Data or object to
              us processing the same. Where personal data is held electronically
              in a structured form, such you have a right to receive that data
              in a common electronic format.
            </li>
            <li>
              The right to update your Personal Data that is kept with us. You
              may do this at anytime your personal data changes and you wish to
              update us.
            </li>
            <li>
              The right to receive your Personal Data and have it transferred to
              another Data Controller, as applicable.
            </li>
            <li>The right to lodge a complaint.</li>
          </ol>
          <br />
          You may exercise any of the above stated rights following our Data
          Subject Access Request Procedure.
        </p>

        <h5 className="egf-title-2 mb-4">Disclosure Of Personal Data</h5>
        <p className="egf-pg mb-5">
          In addition to our staff who have a business need to know, the
          following trusted third parties have access to your information:
          <br />
          <br />
          <ol type="1">
            <li>
              We use a customer relationship management tool to help manage our
              contact database and send out email communications to you.
            </li>
            <li>
              Our Affiliate Third Parties who require your information for the
              same purposes described in this Policy and who have adopted
              similar privacy policy standards further to contractual
              obligations to us under a third party data processing agreement we
              have entered with them.
            </li>
            <li>
              Professional service providers such as IT service providers and
              website hosts.
            </li>
          </ol>
          <br />
          We will transfer your Personal Data to only those Affiliated Third
          Parties who we are sure can offer the required level of protection to
          your privacy and information and who are also contractually obligated
          to us to do so. We do not and will not at any point in time sell your
          Personal Data. We require all Affiliated Third Parties to respect the
          security of your personal data and to treat it in accordance with the
          law. We do not allow our professional service providers to use your
          Personal Data for their own purposes and only permit them to process
          your Personal Data for specified purposes and in accordance with our
          instructions.
        </p>

        <h5 className="egf-title-2 mb-4">Security And Confidentiality</h5>
        <p className="egf-pg mb-5">
          Information submitted by you is stored on secure servers we have which
          are encrypted and access is restricted to only authorised persons in
          charge of maintaining the servers. We have put in place physical,
          electronic and procedural processes that safeguard and protect your
          information against unauthorised access, modification or erasure.
          However, we cannot guarantee 100% security as no security programme is
          completely fool proof. In the unlikely event that we experience any
          breach to your personal data, such breach shall be handled in
          accordance with our Personal Data Breach Management Procedures. All
          such breaches shall be notified to the NITDA within 72 hours of
          occurrence and where deemed necessary, based on the severity and
          potential risks, we shall notify you of such occurrence, steps taken
          and remedies employed to prevent a reoccurrence. Our staff also have
          an obligation to maintain the confidentiality of any Personal Data
          held by us. As you know, transmission of data on the internet is never
          guaranteed regarding safety. It is impossible to completely guarantee
          your safety with electronic data and transmission. You are therefore
          at your own risk if you elect to transmit any data electronically.
        </p>

        <h5 className="egf-title-2 mb-4">
          International Transfer Of Personal Data
        </h5>
        <p className="egf-pg mb-5">
          The Personal Data we collect may be transferred to and processed in
          another country other than your country of residence for the purposes
          stated above. The data protection laws in those countries may be
          different from, and less stringent than the laws applicable in your
          country of residence. By accepting this Policy or by providing your
          Personal Data to us, you expressly consent to such transfer and
          Processing. We will however take all reasonable steps to ensure that
          your data is treated securely and transfer of your Personal Data will
          only be done in accordance with the requirements of applicable laws
          and to parties who have put in place adequate controls to secure and
          protect your Personal Data.
        </p>

        <h5 className="egf-title-2 mb-4">Retention Of Personal Data</h5>
        <p className="egf-pg mb-5">
          We retain your Personal Data for no longer than reasonably necessary
          for the purposes set out in this Policy and in accordance with legal,
          regulatory, tax, accounting or reporting requirements. We may retain
          your Personal Data for a longer period in the event of a complaint or
          if we reasonably believe there is a prospect of litigation in respect
          to our relationship with you. To determine the appropriate retention
          period for personal data, we consider the amount, nature and
          sensitivity of the Personal Data, the potential risk of harm from
          unauthorised use or disclosure of your Personal Data, the purposes for
          which we process your Personal Data and whether we can achieve those
          purposes through other means, and the applicable legal, regulatory,
          tax, accounting or other requirements. Where your Personal Data is
          contained within a document, the retention period applicable to such
          type of document in our document retention policy shall apply.
        </p>

        <h5 className="egf-title-2 mb-4">
          Advertising Services Provided by Others
        </h5>
        <p className="egf-pg mb-5">
          We may allow others to provide analytics services on our behalf and to
          serve advertisements on our behalf across the Internet. These entities
          may use cookies, web beacons and other technologies to collect
          information about your use of the Services and other websites,
          including your IP address, web browser, pages viewed, time spent on
          pages, links clicked and conversion information. This Personal Data
          may be used by us and others, to, among other things, analyze and
          track data, determine the popularity of certain content, deliver
          advertising targeted to your interests and better understand your
          online activity.
        </p>

        <h5 className="egf-title-2 mb-4">Cookies</h5>
        <p className="egf-pg mb-5">
          Most web browsers are set to accept cookies by default. Our website
          uses cookies to distinguish you from other users of our website. This
          helps us to provide you with a good experience when you browse our
          website and also allows us to improve our site.
        </p>
        <br />
        <p className="egf-pg mb-5">
          To exercise your right to query, access and amend your personal data,
          you can contact us by email on{" "}
          <a href="mailto:compliance@elizabethgracefoundation.com">
            compliance@elizabethgracefoundation.com
          </a>
        </p>
      </section>
    </>
  );
};
