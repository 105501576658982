import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MainRoutes } from "./routes/main-routes";

function App() {
  return (
    <>
      <MainRoutes />
    </>
  );
}

export default App;
