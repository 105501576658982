import React, { useRef, useState } from "react";
import { Navbar } from "../../components/Nav";
import CLoseModalIcon from "../../assets/images/icon/close-modal-icon.svg";
import Modal from "react-bootstrap/Modal";
import emailjs from "@emailjs/browser";
import ModalLike from "../../assets/images/icon/modal-like.svg";
import { Bounce } from "react-reveal";
import { Helmet } from "react-helmet";

export const Donate = () => {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .sendForm(
        "service_bssqhmm",
        "template_12l8bur",
        form.current,
        "BaqDeyNTjIsPMpGQS"
      )
      .then(
        (result) => {
          document.getElementById("donate-form").reset();

          console.log(result.text);
          setIsLoading(false);
          setShow(true);
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false);
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Donate to us - Elizabeth Grace Foundation</title>
        <meta
          name="description"
          content="You can join in our vision today by donating. Kindly fill the form and we will get back to you as soon as possible"
        />
      </Helmet>

      <header>
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-5 text-center">
            <h3 className="egf-title mb-4">
              <Bounce>Get Involved In What We Are Doing</Bounce>
            </h3>
          </div>

          <div className="row mx-0">
            <div className="col-12">
              <section className="contact-form-session">
                <form
                  className=""
                  ref={form}
                  onSubmit={sendEmail}
                  id="donate-form"
                >
                  <div className="form-group mb-4">
                    <label for="name" class="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label for="phone" class="form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="user_phone"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label for="email" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="user_email"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label for="address" class="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      name="user_address"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label for="purpose" class="form-label">
                      Purpose
                    </label>
                    <textarea
                      rows="10"
                      name="purpose"
                      className="form-control mb-5"
                      required
                    ></textarea>
                  </div>

                  <input
                    type="submit"
                    className="btn egf-btn-primary w-100"
                    value="Send"
                    disabled={isLoading}
                  />
                </form>
              </section>
            </div>

            {/* Contact Response modal */}
            <Modal show={show} onHide={handleClose} centered>
              <div className="close-modal" onClick={handleClose}>
                <img src={CLoseModalIcon} alt="close modal" />
              </div>
              <Modal.Body className="messaged-received-modal">
                <p class="mb-0">
                  Your request to donate been received, We'll get back to you
                  soon.
                </p>
                <h5 className="my-3">Thank You!</h5>
                <img src={ModalLike} alt="like" />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </header>
    </>
  );
};
