import React, { useState, useRef } from "react";
import { Navbar } from "../../components/Nav";
import "./contact,style.css";
import FbIcon from "../../assets/images/icon/fb-contact.svg";
import IGIcon from "../../assets/images/icon/IG-contact.svg";
import TwitterIcon from "../../assets/images/icon/Twitter-contact.svg";
// import YouTubeeIcon from "../../assets/images/icon/YouTube-contact.svg";
import LinkedInIcon from "../../assets/images/icon/EGF_LINKEDIN.svg";
import ModalLike from "../../assets/images/icon/modal-like.svg";
import CLoseModalIcon from "../../assets/images/icon/close-modal-icon.svg";
import Modal from "react-bootstrap/Modal";
import emailjs from "@emailjs/browser";
import { Bounce } from "react-reveal";
import { Helmet } from "react-helmet";

export const Contact = () => {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .sendForm(
        "service_bssqhmm",
        "template_8s2mgx1",
        form.current,
        "BaqDeyNTjIsPMpGQS"
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();

          console.log(result.text);
          setIsLoading(false);
          setShow(true);
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false);
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Elizabeth Grace Foundation</title>
        <meta
          name="description"
          content="Contact Elizabeth Grace Foundation today via emial or phone number for assistance or to donate"
        />
      </Helmet>

      <header className="con-header">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-3 py-lg-5 text-center">
            <h3 className="egf-title mb-4">
              <Bounce> We Want To Hear From You </Bounce>
            </h3>
          </div>
        </div>
      </header>

      <section className="container">
        <div className="row mx-0">
          <div className="col-12">
            <section className="contact-form-session">
              <form
                className=""
                ref={form}
                onSubmit={sendEmail}
                id="contact-form"
              >
                <div className="form-group mb-4">
                  <label for="name" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label for="phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    name="user_phone"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label for="email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label for="message" class="form-label">
                    Message
                  </label>
                  <textarea
                    rows="10"
                    name="message"
                    className="form-control mb-5"
                    required
                  ></textarea>
                </div>

                <input
                  type="submit"
                  className="btn egf-btn-primary w-100"
                  value="Send"
                  disabled={isLoading}
                />
              </form>
            </section>
          </div>

          <div className="col-md-6">
            <div className="card contact-card p-lg-5 p-4">
              <h5 className="title mb-lg-5 mb-4">Get In Touch</h5>

              <p className="mb-2 d-flex">
                Call:
                <div className="ms-3">
                  <a href="tel:+2348034290872">+234 803 429 0872</a>
                  <br />
                  <a href="tel:+234 803 370 4979">+234 803 370 4979</a>
                </div>
              </p>
              <p className="mb-2">
                Mail:
                <a
                  className="ms-3"
                  href="mailto: info@elizabethgracefoundation.com "
                >
                  info@elizabethgracefoundation.com
                </a>
              </p>

              <div className="d-flex mt-auto">
                <a
                  href="https://www.facebook.com/profile.php?id=100085566764064"
                  target="_blank"
                  className="icon-holder me-3"
                >
                  <img src={FbIcon} alt="Elizabeth Grace Foundation Facebook" />
                </a>
                <a
                  href="https://instagram.com/elizabethgracefoundationng?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  className="icon-holder me-3"
                >
                  <img
                    src={IGIcon}
                    alt="Elizabeth Grace Foundation Instagram"
                  />
                </a>
                <a
                  href="https://twitter.com/EGF_NG"
                  target="_blank"
                  className="icon-holder me-3"
                >
                  <img
                    src={TwitterIcon}
                    alt="Elizabeth Grace Foundation Twitter"
                  />
                </a>
                <a href="#" className="icon-holder me-3">
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.71383 4.00195H1.23828V12H3.71383V4.00195Z"
                      fill="#FAB415"
                    />
                    <path
                      d="M2.46431 2.95063C2.85578 2.95063 3.23121 2.79519 3.50802 2.51852C3.78483 2.24184 3.94034 1.86659 3.94034 1.47531C3.94034 1.08404 3.78483 0.708785 3.50802 0.43211C3.23121 0.155435 2.85578 5.83049e-09 2.46431 0C2.07284 5.83049e-09 1.69741 0.155435 1.4206 0.43211C1.14379 0.708785 0.988281 1.08404 0.988281 1.47531C0.988281 1.86659 1.14379 2.24184 1.4206 2.51852C1.69741 2.79519 2.07284 2.95063 2.46431 2.95063Z"
                      fill="#FAB415"
                    />
                    <path
                      d="M7.67656 7.80071C7.67656 6.67389 8.19375 6.00594 9.18746 6.00594C10.1812 6.00594 10.5356 6.65066 10.5356 7.80071V12.0001H12.9996V6.93527C12.9996 4.792 11.785 3.75811 10.094 3.75811C9.61233 3.75103 9.13703 3.86876 8.71441 4.09982C8.29178 4.33088 7.93623 4.6674 7.68237 5.0766V4.00206H5.2998V12.0001H7.67656V7.80071Z"
                      fill="#FAB415"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card contact-card p-lg-5 p-4">
              <h5 className="title mb-lg-5 mb-4">Office</h5>

              <p className="mb-2">
                Plot 822, Samuel Manuwa Street, Victoria Island, Lagos, Nigeria
              </p>
            </div>
          </div>

          <div className="col-12 google-map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6667580273333!2d3.4336800146311997!3d6.436828895343034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf520e78e2867%3A0x15d9e7adc651c130!2sSir%20Samuel%20Manuwa%20St%2C%20Victoria%20Island%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1660156286398!5m2!1sen!2sng"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Contact Response modal */}
          <Modal show={show} onHide={handleClose} centered>
            <div className="close-modal" onClick={handleClose}>
              <img src={CLoseModalIcon} alt="close modal" />
            </div>
            <Modal.Body className="messaged-received-modal">
              <p class="mb-0">Your Message has been received</p>
              <h5 className="my-3">Thank You!</h5>
              <img src={ModalLike} alt="like" />
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  );
};
