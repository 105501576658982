import React, { useState } from "react";
import "./trustee.style.css";
import { Fade } from "react-reveal";

export const TrusteeCard = (props) => {
  const openReadMore = () => {
    props.onOpenModal(props.bio_detail);
  };

  return (
    <>
      <div className="card trustee-card">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <img src={props.photo} alt={props.photo_alt} className="img-fluid" />
          </div>
          <div className="col-lg-9 col-md-8">
            <div className="py-lg-2 px-xl-5">
              <h5 className="title mb-1">{props.name}</h5>
              <p className="sub-title mb-xl-4 mb-lg-3 mb-2">{props.title} </p>

              <p className="bio mb-4">
                <Fade>{props.bio_summary}</Fade>
              </p>

              <button
                className="btn egf-btn-primary"
                onClick={() => openReadMore()}
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
