import React from "react";
import { Navbar } from "../../components/Nav";
import { Helmet } from "react-helmet";

export const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Terms Of Use - Elizabeth Grace Foundation</title>
      </Helmet>
      <header className="abt-header egf-header-bg">
        <div className="container">
          {/* <Navbar /> */}

          <div className="details py-lg-5 py-4">
            <h3 className="egf-header-title mb-4">Terms Of Use</h3>
            <p className="egf-pg">Document amended September 20, 2022</p>
          </div>
        </div>
      </header>

      <section className="container py-5 my-3">
        <h4 className="egf-title-2 mb-4">Introduction</h4>
        <p className="egf-pg mb-5">
          These Terms and Conditions as well as the Eligibility Criteria,
          Application Process and Selection Process are collectively referred to
          as the Rules of the Programme (“Rules”). <br />
          <br />
          Successful Applicants shall be subject to these Rules, the
          Participation Agreement and other rules and guidelines published by
          the Foundation from time to time for the running of the Programme.{" "}
          <br />
          <br />
          The Promoter reserves the right to cancel or amend all or any part of
          the Rules without notice. Any changes to the Rules will be posted on
          the Programme’s website. It is the responsibility of Successful
          Applicants to keep themselves informed as to any changes to the Rules.
          The Promoter shall not be liable for any loss suffered by a Successful
          Applicant due to any changes to the Rules, including failure to
          complete some or all tasks under the Programme due to such change.{" "}
          <br />
          <br />
          If for any reason the Programme is not capable of running as planned
          for reasons including but not limited to tampering, unauthorised
          intervention, fraud, technical failures, force majeure events or any
          other causes beyond the control of the Promoter which corrupt or
          affect the administration, security, fairness, integrity or proper
          conduct of this Programme, the Promoter reserves the right to cancel,
          terminate, modify or suspend the Programme and disqualify any
          Successful Applicant. The Promoter shall not be liable for any loss
          arising from modification, suspension or cancellation of the
          Programme.
        </p>
      </section>
    </>
  );
};
