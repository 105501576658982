import React from "react";
import "./footer.style.css";
import FbIcon from "../../assets/images/icon/EGF_FB.svg";
import IgIcon from "../../assets/images/icon/EGF_IG.svg";
import TwitterIcon from "../../assets/images/icon/EGF_TWITTER.svg";
import YouTubeIcon from "../../assets/images/icon/EGF_YOUTUBE.svg";
import LinkedInIcon from "../../assets/images/icon/EGF_LINKEDIN.svg";
import EgfLogo from "../../assets/images/logo/EGF-FooterLogo.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-4 d-flex flex-column mb-4 mb-lg-0">
            <div className="">
              <h5 className="connect-text">Connect With Us:</h5>
              <div className="social-icon-container">
                <a
                  className="icon-wrapper me-3 me-lg-2"
                  href="https://www.facebook.com/profile.php?id=100085566764064"
                  target="_blank"
                >
                  <img src={FbIcon} alt="Elizabeth Grace Foundation Facebook" />
                </a>
                <a
                  href="https://instagram.com/elizabethgracefoundationng?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  className="icon-wrapper me-3 me-lg-2"
                >
                  <img
                    src={IgIcon}
                    alt="Elizabeth Grace Foundation Instagram"
                  />
                </a>
                <a
                  href="https://twitter.com/EGF_NG"
                  target="_blank"
                  className="icon-wrapper me-3 me-lg-2"
                >
                  <img
                    src={TwitterIcon}
                    alt="Elizabeth Grace Foundation Twitter"
                  />
                </a>
                <a href="" target="_blank" className="icon-wrapper">
                  <img
                    src={LinkedInIcon}
                    alt="Elizabeth Grace Foundation LinkedIn"
                  />
                </a>
              </div>
            </div>

            <Link
              to="/"
              className="footer-logo align-self-start mt-5 mb-4  mt-lg-auto mb-lg-0"
            >
              <img src={EgfLogo} alt="footer_logo" />
            </Link>
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <h5 className="footer-sub-title">Quick Links</h5>
            <ul className="list-group quik-link-list">
              <li className="list-group-item">
                <Link to="/about">About Us</Link>
              </li>
              <li className="list-group-item">
                <Link to="what-we-do">What We Do</Link>
              </li>
              <li className="list-group-item">
                <Link to="contact"> Contact Us</Link>
              </li>
              <li className="list-group-item">
                <Link to="events"> Events</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <h5 className="footer-sub-title">Get In Touch</h5>
            <ul className="list-group quik-link-list">
              <li className="list-group-item">
                <small>Call:</small>
                <p className="mb-0 fw-500">
                  <a href="tel:+2348034290872">+234 803 429 0872</a>
                  <br />
                  <a href="tel:+234 803 370 4979">+234 803 370 4979</a>
                </p>
              </li>
              <li className="list-group-item">
                <small>Office Address:</small>
                <p className="mb-0 me-lg-4 me-4 fw-500">
                  Plot 822, Samuel Manuwa Street, Victoria Island, Lagos,
                  Nigeria
                </p>
              </li>
              <li className="list-group-item">
                <small>Mail:</small>
                <p className="mb-0 fw-500">
                  <a href="mailto: info@elizabethgracefoundation.com ">
                    info@elizabethgracefoundation.com
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mx-lg-0 lower-footer-section d-flex">
          <div className="col-lg-5 ps-lg-0">
            <p className="all-right-text mb-4 mb-lg-0 text-center text-lg-start">
              &copy; {new Date().getFullYear()}, Elizabeth Grace Foundation |
              All Rights Reserved
            </p>
          </div>
          <div className="col-lg-2 col-6 order-1">
            <Link to="terms-of-use" className="terms-text mb-0">
              Terms and Conditions
            </Link>
          </div>
          <div className="col-lg-5 col-6 d-flex justify-content-lg-end pe-lg-0 order-2">
            <Link to="privacy-policy" className="ms-auto mb-0">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
